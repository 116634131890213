import React from 'react'
import Wrapper from '../components/wrapper'
import Contact from '../components/pageComponents/contact'
export default function ContactPage({ location }) {
  return (
    <Wrapper location={location} title="Contact Us | MedReps"
      description="Have a question or need assistance? Contact us for prompt and friendly support. We value your feedback and are committed to providing exceptional service to our customers.">
      <Contact />
    </Wrapper>
  )
}
