import React from 'react'
import HubspotForm from 'react-hubspot-form'
import useGMT from '../../../hooks/useGTM'
import { HUBSPOT_INTEGRATION } from '../../../data'

const Contact = () => {
  const { pushContactUsEvent } = useGMT()

  const handleSubmit = () => {
    console.log('Submit!')
    pushContactUsEvent()
  }

  return (
    <>
      <div className="container mx-auto min-h-700px py-12">
        <div className="px-6 sm:px-0 w-full sm:w-1/2 mx-auto">
          <h1 className="font-playfair text-2xl font-semibold pb-2">
            Contact Us
          </h1>
          <p className="font-sans text-base mb-0 pb-4">
            We want to hear from you. Just complete the form below to send us a
            message and our team will be in touch as soon as possible.
          </p>
          <p className="font-sans text-base mb-0 pb-4">
            Are you a candidate looking to modify or cancel your membership?
            Simply log into your MedReps account, then visit{' '}
            <a href="/account-candidate" target="_blank">
              Account
            </a>{' '}
            to make changes.
          </p>
          <HubspotForm
            portalId={HUBSPOT_INTEGRATION.PORTAL_ID}
            formId={HUBSPOT_INTEGRATION.CONTACT_FORM_ID}
            onSubmit={handleSubmit}
            onReady={() => {
              const script = document.createElement('script')
              script.src =
                'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
              let jqueryScript = document.getElementsByTagName('script')
              let src = Array.from(jqueryScript).filter(
                item => item.src === script.src
              )
              if (src.length === 0) {
                document.body.appendChild(script)
              }
            }}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
    </>
  )
}
export default Contact
